import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from 'react';
import { Chart as ChartJS } from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import API_BASE_URL from './const.js';

const MultiStockAnalysis = () => {
    const [stockName, setStockName] = useState('NVDA');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [stockData, setStockData] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const today = new Date();
        today.setDate(today.getDate() - 1); // 设置为昨天
        const eightDaysAgo = new Date();
        eightDaysAgo.setDate(today.getDate() - 6);
        
        setEndDate(today.toISOString().split('T')[0]);
        setStartDate(eightDaysAgo.toISOString().split('T')[0]);
    }, []);

    const isDateRangeValid = (start, end) => {
        const startDate = new Date(start);
        const endDate = new Date(end);
        const minDate = new Date('2020-01-01');
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        if (startDate < minDate) {
            setError('开始日期必须在2020-01-01之后。');
            return false;
        } else if (endDate > today) {
            setError('结束日期不能在未来。');
            return false;
        } else if (startDate > endDate) {
            setError('开始日期不能晚于结束日期。');
            return false;
        } else {
            setError('');
            return true;
        }
    };

    const isValidStock = async (stock) => {
        const url = `${API_BASE_URL}/api/validate_stock?stock_name=${stock}`;
        try {
            const response = await fetch(url);
            const data = await response.json();
            return data.valid;
        } catch (error) {
            console.error('Error validating stock symbol:', error);
            return false;
        }
    };

    const handleAnalyze = async () => {
        if (!startDate || !endDate) {
            setError('请选择开始和结束日期。');
            return;
        }

        if (!isDateRangeValid(startDate, endDate)) {
            return;
        }

        console.log(stockName);
        const isValid = await isValidStock(stockName);
        console.log(isValid);
        if (!isValid) {
            setError('Invalid stock symbol. Please enter a valid stock symbol, e.g., NVDA for Nvidia, AAPL for Apple Inc.');
            return;
        }

        setLoading(true);
        setError('');

        const token = await getAccessTokenSilently();
        try {
            const response = await fetch(
                `${API_BASE_URL}/api/get_stock_analysis_with_multi_dates?stock_name=${stockName}&start_date=${startDate}&end_date=${endDate}`,
                { headers: { 'Authorization': `Bearer ${token}` } }
            );
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.detail);
            }
            setStockData(await response.json());
            console.log(stockData);
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const getPointBackgroundColor = (data) => {
        if (data.BuyOrSell === 1) return 'green'; // Buy
        if (data.BuyOrSell === 0) return 'red'; // Sell
        if (data.BuyOrSell === -1) return 'black'; // Neutral
        return 'gray'; // Missing/Unpredicted data
    };

    const getPointStyle = (data) => {
        if (data.BuyOrSell === 1 || data.BuyOrSell === 0) return 'triangle';
        return 'circle';
    };

    const getPointRotation = (data) => {
        if (data.BuyOrSell === 1) return 0;
        if (data.BuyOrSell === 0) return 180;
        return 0;
    };

    // 更新图表数据生成逻辑
    const generateChartData = () => {
        const datasets = [
            {
                type: 'line',
                label: 'Closing Price',
                data: stockData.map((data) => ({ x: data.date, y: data.close })) || [],
                borderColor: '#FF6384',
                backgroundColor: '#FF6384',
                borderWidth: 2,
                fill: false,
                yAxisID: 'y',
                pointBackgroundColor: stockData.map((data) => getPointBackgroundColor(data)) || [],
                pointStyle: stockData.map((data) => getPointStyle(data)) || [],
                pointRotation: stockData.map((data) => getPointRotation(data)) || [],
                pointRadius: stockData.map((data) => (data.BuyOrSell !== null || data.profit === null ? 8 : 4)) || []
            },
            {
                type: 'bar',
                label: 'Buy Score',
                data: stockData.map((data) => ({ x: data.date, y: data.BuyScore })) || [],
                backgroundColor: '#36A2EB',
                yAxisID: 'y1',
                barThickness: 6,
                barPercentage: 0.5,
                categoryPercentage: 0.5,
            }
        ];

        return {
            datasets: datasets
        };
    };


    const chartOptions = {
        responsive: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    generateLabels: (chart) => {
                        const original = ChartJS.defaults.plugins.legend.labels.generateLabels(chart);
                        return [
                            ...original,
                            {
                                text: 'Buy',
                                fillStyle: 'green',
                                pointStyle: 'triangle',
                            },
                            {
                                text: 'Sell',
                                fillStyle: 'red',
                                pointStyle: 'triangle',
                            },
                            {
                                text: 'Neutral',
                                fillStyle: 'black',
                                pointStyle: 'circle',
                            },
                            {
                                text: 'Missing/Unpredicted',
                                fillStyle: 'gray',
                                pointStyle: 'circle',
                            }
                        ];
                    }
                }
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += context.parsed.y.toFixed(2);
                        }
                        return label;
                    }
                }
            }
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day'
                }
            },
            y: {
                type: 'linear',
                display: true,
                position: 'left',
                title: {
                    display: true,
                    text: 'Stock Price'
                }
            },
            y1: {
                type: 'linear',
                display: true,
                position: 'right',
                grid: {
                    drawOnChartArea: false,
                },
                min: 0,
                max: 1,
                title: {
                    display: true,
                    text: 'Buy Score'
                }
            }
        }
    };

    return (
            <div className="chart-tab">
                <div className="input-container">
                    <input
                        type="text"
                        value={stockName}
                        onChange={(e) => setStockName(e.target.value)}
                        placeholder="Stock symbol (e.g., NVDA)"
                        className="input"
                    />
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        className="input"
                    />
                    <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        className="input"
                    />
                    <button onClick={handleAnalyze} className="button" disabled={loading}>
                        {loading ? 'Analyzing...' : 'Analyze Stock'}
                    </button>
                </div>
                {error && <div className="error">{error}</div>}
                {loading && <div className="loading">Loading, it usually takes around 15 seconds</div>}

                {stockData.length > 0 && (
                    <Line data={generateChartData()} options={chartOptions} />
                )}
            </div>
    );
};

export default MultiStockAnalysis;
